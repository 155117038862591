body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}

.scrollable_content {
  padding-bottom: 90px;
}

.flex {
  display: flex !important;
}

ons-list-item.isDead {
  background-color: lightgray;
}

.isDead>div {
  text-decoration-line: line-through;
}

.player>div {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: nowrap;
}

.player>div div {
  flex-shrink: 0;
  flex-grow: 0;
}

.player>div div:nth-child(2) {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

ons-list-item:not(.isDead) button {
  color: white;
  border-color: #0076ff;
  background-color: #0076ff;
}

.checkbox--noborder__checkmark:after {
  border-right-style: none !important;
  border-top-style: none !important;
}

.effect-list {
  max-height: 80vh;
  overflow-y: auto!important;
}

.effect-list .list-item {
  margin-bottom: 0;
}

.button>ons-icon {
  width: 20px;
  text-align: center;
}

.effect-form-wrapper {
  background-color: white;
  padding: 24px;
}

.effect-form-wrapper ons-input {
  width: 100%;
}

.icon-list {
  display: flex!important;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
  margin-top: 24px;
  max-height: 50vh;
  overflow-y: auto;
}

.icon-list-element {
  padding: 0;
}

.icon-list-element input[type="radio"]:checked~label>ons-button {
  color: white;
  border-color: #0076ff;
  background-color: #0076ff;
}

label.icon-text>ons-icon {
  margin-right: 12px;
}

ons-list-item button.button--dialog,
.button--dialog {
  background-color: transparent;
  border: none;
  color: gray;
  padding: 12px 15px;
}

.button--dialog:active {
  background-color: #0076ff;
  color: white;
}